// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "e3ff7eba-21b0-4fbf-ad89-21aa3b28a860",
        authority: "https://login.microsoftonline.com/1456153d-c538-4dcf-b3c6-6faf5a4b5147",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};