import { navigate } from "gatsby-link"
import {
  HORIZON_INTERNAL_ADMINDASHBOARD,
  HORIZON_INTERNAL_CXDASHBOARD,
  HORIZON_INTERNAL_SOLUTIONSDASHBOARD,
  HORIZON_INTERNAL_FORBIDDEN,
  HORIZON_INTERNAL_DASHBOARDADMIN,
  HORIZON_INTERNAL_ADMIN,
  HORIZON_INTERNAL_CXTEAM,
  HORIZON_INTERNAL_SOLUTIONSTEAM,
} from "./constants"

export const permissionsRoutingHelper = (
  permissions: Array<string>,
  pathname: string,
  isAuthenticated: boolean
) => {
  const cxPathRegexStr = `^${HORIZON_INTERNAL_CXDASHBOARD}`
  const cxRegex = new RegExp(cxPathRegexStr, "i")
  const solutionsPathRegexStr = `^${HORIZON_INTERNAL_SOLUTIONSDASHBOARD}`
  const solutionsRegex = new RegExp(solutionsPathRegexStr, "i")
  const isAdminPage = pathname === HORIZON_INTERNAL_ADMINDASHBOARD
  const isCXDashboardPath = cxRegex.test(pathname)
  const isSolutionsDashboardPath = solutionsRegex.test(pathname)

  const isFullAdmin =
    permissions?.includes(HORIZON_INTERNAL_DASHBOARDADMIN) ||
    permissions?.includes(HORIZON_INTERNAL_ADMIN)
  const canAccessCXDashboard =
    isFullAdmin || permissions?.includes(HORIZON_INTERNAL_CXTEAM)
  const canAccessSolutionsDashboard =
    isFullAdmin || permissions?.includes(HORIZON_INTERNAL_SOLUTIONSTEAM)
  const canFullAccess =
    isFullAdmin || (canAccessCXDashboard && canAccessSolutionsDashboard)
  const noPermissions = !permissions || permissions.length === 0

  if (!isAuthenticated && !isAdminPage) {
    return navigate("/")
  }
  if (isAuthenticated && noPermissions) {
    return navigate(HORIZON_INTERNAL_FORBIDDEN)
  }

  if (isCXDashboardPath && !canAccessCXDashboard) {
    return navigate(HORIZON_INTERNAL_FORBIDDEN)
  }

  if (isSolutionsDashboardPath && !canAccessSolutionsDashboard) {
    return navigate(HORIZON_INTERNAL_FORBIDDEN)
  }

  if (isAdminPage && !canFullAccess) {
    if (canAccessCXDashboard) {
      return navigate(HORIZON_INTERNAL_CXDASHBOARD)
    }
    if (canAccessSolutionsDashboard) {
      return navigate(HORIZON_INTERNAL_SOLUTIONSDASHBOARD)
    }
  }
}
