exports.components = {
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cx-dashboard-customer-tsx": () => import("./../../../src/pages/cx-dashboard/customer/[...].tsx" /* webpackChunkName: "component---src-pages-cx-dashboard-customer-tsx" */),
  "component---src-pages-cx-dashboard-index-tsx": () => import("./../../../src/pages/cx-dashboard/index.tsx" /* webpackChunkName: "component---src-pages-cx-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-solutions-dashboard-contract-tsx": () => import("./../../../src/pages/solutions-dashboard/contract/[...].tsx" /* webpackChunkName: "component---src-pages-solutions-dashboard-contract-tsx" */),
  "component---src-pages-solutions-dashboard-index-tsx": () => import("./../../../src/pages/solutions-dashboard/index.tsx" /* webpackChunkName: "component---src-pages-solutions-dashboard-index-tsx" */)
}

