import React, { useEffect } from "react"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react"
import { LoginSplashScreen } from "../login/loginSplashScreen"
import { permissionsRoutingHelper } from "../../utils/permissons-routing-helper"

export const AuthenticationLayer: React.FC = ({ children }) => {
  const { accounts, instance, inProgress } = useMsal() || {}
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (inProgress === "none") {
      const account: Record<string, any> | null = instance.getAccountByHomeId(
        accounts[0]?.homeAccountId
      )

      // Get the user Id into the GTM data layer
      let dataLayer = (window as any).dataLayer
      if (dataLayer && accounts[0]?.localAccountId){
        dataLayer.push({userId: accounts[0].localAccountId, event: "authenticate"})
      }

      const roles = account?.idTokenClaims?.roles
        permissionsRoutingHelper(
          roles,
          window.location.pathname,
          isAuthenticated
        )
    }
  }, [accounts, isAuthenticated, inProgress])

  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginSplashScreen />
      </UnauthenticatedTemplate>
    </>
  )
}
