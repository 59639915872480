import React from "react"
import { SignInButton } from "../msal/signInButton"
import HorizonLogo from "../../images/horizon-icon.svg"
import "./style.scss"

export const LoginSplashScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img
        id="horizon-logo"
        src={HorizonLogo}
        alt="Horizon Logo"
        className="mb-10"
      />
      <SignInButton />
    </div>
  )
}
