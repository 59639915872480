import React from "react"
import { useMsal } from "@azure/msal-react"

export const SignInButton = () => {
  const { instance } = useMsal()

  const handleLogin = () => {
    instance.loginRedirect().catch((e: any) => {
      console.error(e)
    })
  }

  return (
    <button
      onClick={() => handleLogin()}
      className="bg-horizonred hover:bg-horizonhover text-white font-bold py-2 px-4 rounded-full transition-colors uppercase w-40"
    >
      Login
    </button>
  )
}
