import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msalConfig";
import { AuthenticationLayer } from "./components/msal/authenticationLayer";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./application-insights";
import "./styles/global.css";

const msalInstance = new PublicClientApplication(msalConfig);

export const WrapWithProvider = ({element}) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalProvider instance={msalInstance}>
                <div className="bg-gradient-to-b from-gray-300 to-white">
                    <AuthenticationLayer>
                        {element}
                    </AuthenticationLayer>
                </div>
            </MsalProvider>
        </AppInsightsContext.Provider>
    );
};

