import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin} from '@microsoft/applicationinsights-react-js'

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.GATSBY_APPLICATION_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: [
        "localhost",
        "*.azure-api.net",
        "*.commercetools.com",
        "*.googleapis.com",
        "*.what3words.com",
    ],
  }
})

appInsights.loadAppInsights()

// We want to log the body of any GraphQL requests, so we need to monitor dependencies and if they
// are GraphQL posts then add the body to the context so it can get picked up in the initializer.
var dependencyListener = (details) => {
  if (details.init.method && details.init.method === "POST" && details.input && details.input.endsWith("/graphql")) {
    details.context.body = details.init.body
  }
}

appInsights.addDependencyListener(dependencyListener)

// If we have a body in context, add it to the dependency telemetry.
var dependencyInitializer = (details) => {
  if (details.context && details.context.body) {
    details.item.properties.body = details.context.body
  }
}

appInsights.addDependencyInitializer(dependencyInitializer)

export { reactPlugin, appInsights }